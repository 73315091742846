.showing {
  transition-timing-function: ease-in;

  /* Quick on the way out */
  transition: 0.2s;

  /* Hide thing by pushing it outside by default */
  transform: translateY(130%);
}

.shown {
  /* This timing applies on the way IN */
  transition-timing-function: ease-out;

  /* A litttttle slower on the way in */
  transition: 0.25s;

  /* Move into place */
  transform: translateY(0);
}

.custom-animation {
  transition: height 4s ease-in-out;
  translate: all 4s ease-in-out;

}

p {
  color: black;
}

.text-bold {
  font-weight: 600;
}

.student-search-card:hover{
  background-color: beige !important;
}

* {
  font-family: "Montserrat", sans-serif !important;
}
