.loader:after {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #ab0d47;
  box-shadow: 20px 0 #ab0d47, -20px 0 #ab0d47;
  animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: rgba(255, 60, 0, 0.25);
    box-shadow: 20px 0 rgba(255, 60, 0, 0.25), -20px 0 #ab0d47;
  }
  50% {
    background-color: #ab0d47;
    box-shadow: 20px 0 rgba(255, 60, 0, 0.25), -20px 0 rgba(255, 60, 0, 0.25);
  }
  100% {
    background-color: rgba(255, 60, 0, 0.25);
    box-shadow: 20px 0 #ab0d47, -20px 0 rgba(255, 60, 0, 0.25);
  }
}
