//sidebar
.iq-chat-tab{
    margin-top: 1.25rem; 
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 0.5rem;
    .nav-link{
        color: unset;
        padding: unset;
        &.active, &:hover{
            color: var(--#{$variable-prefix}primary);
            background: unset;
            border: unset;
        }
    }
}

.sidebar-default{
    .navbar-nav{
        .nav-item{
            .nav-link{
                text-transform: unset;
                &:hover, &.active{
                    .iq-userlist-data{
                        .iq-userlist-name{
                            color: var(--#{$variable-prefix}primary); 
                        }
                    }
                }
            }
            &.iq-chat-list{
                .iq-userlist-data{
                    .iq-userlist-name{
                        transition: all 300ms ease-in-out;
                        color: $dark;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-down(xl) {
    // .sidebar{
    //     &.sidebar-chat{
    //         // .sidebar-toggle{
    //         //     right: -12px;
    //         // }
    //         &.sidebar-mini{
    //             .sidebar-toggle{
    //                 right: 18px;
    //             }
    //         }
    //     }
    // }
}