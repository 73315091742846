.chat-body {
    height: 100%;
    min-height: calc(100vh - 10rem);
    max-height: calc(100vh - 10rem);
    overflow-y: scroll;
    object-fit: cover;
    // background-image: url(../images/10.svg);
    // background-repeat: repeat;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.sidebar-chat{
    max-width: 22rem;
    @include media-breakpoint-up(xl) {
        &+.main-content{
            margin-left: 22rem;
        }
    }
}
